<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="title"
                    label="标题"
                >
                    <el-input
                        v-model="queryFormModel.title"
                        placeholder="请输入标题"
                    />
                </el-form-item>
                <el-form-item
                    prop="cmsCatalogId"
                    label="分类"
                >
                    <CascaderPicker
                        :api-class="selectApi"
                        v-model="queryFormModel.cmsCatalogId"
                        :min-lv="1"
                        :p-id="0"
                        filterable
                        change-on-select
                    />
                </el-form-item>
                <el-form-item
                    prop="cmsArticleType"
                    label="文章类型"
                    label-width="7em"
                >
                    <dictionaries-picker
                        type="cmsArticleType"
                        v-model="queryFormModel.cmsArticleType"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-dropdown
                        @command="handleCommand"
                    >
                        <el-button
                            type="success"
                            icon="el-icon-plus"
                            size="small"
                        >
                            新增
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="1">
                                新增文章
                            </el-dropdown-item>
                            <el-dropdown-item command="2">
                                新增图片文章
                            </el-dropdown-item>
                            <el-dropdown-item command="3">
                                新增视频文章
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <el-button
                        type="danger"
                        icon="el-icon-delete"
                        size="small"
                        @click="onDelete"
                        :disabled="!currentTableSelect.length"
                    >
                        删除
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="36"
                />
                <el-table-column
                    prop="title"
                    label="标题"
                    min-width="100"
                />
                <el-table-column
                    prop="cmsCatalogName"
                    label="分类"
                    min-width="100"
                />
                <el-table-column
                    prop="cmsArticleTypeName"
                    label="文章类型"
                    min-width="100"
                />
                <el-table-column
                    label="发布时间"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="150"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/pt/cms/cmsArticle/edit/' + scope.row.cmsArticleType + '/' + scope.row.id)"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableDelete(scope.row.id)"
                        >
                            删除
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onViewComment(scope.row.id)"
                        >
                            查看评论
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <el-dialog
            title="文章评论"
            center
            width="1200px"
            :visible.sync="selectDialog.isVisible"
            @closed="onSelectCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectDialog.queryFormModel"
            >
                <el-form-item
                    prop="fullName"
                    label="姓名"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.fullName"
                        placeholder="请输入姓名"
                    />
                </el-form-item>
                <el-form-item
                    prop="mobile"
                    label="电话"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.mobile"
                        placeholder="请输入电话"
                    />
                </el-form-item>
                <el-form-item
                    prop="comments"
                    label="评论内容"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.comments"
                        placeholder="请输入评论内容"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectDialogQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectDialog"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="id"
                    ref="selectDialogTable"
                >
                    <el-table-column
                        prop="fullName"
                        label="姓名"
                        min-width="100"
                    />
                    <el-table-column
                        prop="mobile"
                        label="电话"
                        min-width="100"
                    />
                    <el-table-column
                        prop="comments"
                        label="评论内容"
                        min-width="100"
                    />
                    <el-table-column
                        label="评论时间"
                        min-width="150"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.releaseTime | moment('YYYY-MM-DD HH:mm:ss') }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label="操作"
                        width="45"
                    >
                        <template slot-scope="scope">
                            <el-button
                                type="text"
                                size="small"
                                @click="onDeleteComment(scope.row.id)"
                            >
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectDialog.pagination"
                        @input="onSelectDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    size="small"
                    @click="onSelectCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'CmsArticle',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                title: '',
                cmsCatalogId: '',
                cmsArticleType: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            defaultProps: {
                children: 'children',
                label: 'label',
            },
            selectApi: this.$api.Pt.CmsCatalog,
            cascaderPicker: '',
            selectDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                queryFormModel: {
                    cmsArticleId: '',
                    fullName: '',
                    mobile: '',
                    comments: '',
                },
                pagination: [],
                tableData: [],
            },
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Pt.CmsArticle.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onAdd() {
            this.addDialog.isVisible = true;
            this.$nextTick(() => {
                const { addForm } = this.$refs;
                addForm.resetFields();
                const { formModel } = this.addDialog;
                formModel.id = '';
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onTableEdit(row) {
            const { formModel } = this.addDialog;
            this.$api.Pt.CmsArticle.getDetail({ id: row.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(formModel, res);
            });
            this.addDialog.isVisible = true;
        },
        onTableDelete(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Pt.CmsArticle.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onDelete() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onTableDelete(ids.join(','));
        },
        onAddDialogConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Pt.CmsArticle.save({
                    ...this.addDialog.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.addDialog.isVisible = false;
                });
            });
        },
        onAddDialogCancel() {
            const { addForm } = this.$refs;
            addForm.resetFields();
            const { formModel } = this.addDialog;
            formModel.id = '';
            this.addDialog.isVisible = false;
        },
        handleCommand(command) {
            this.$router.push(`/pt/cms/cmsArticle/edit/${command}`);
        },
        onViewComment(cmsArticleId) {
            this.selectDialog.cmsArticleId = cmsArticleId;
            this.selectDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectDialogQuery();
            });
        },
        onSelectDialogQuery(pagination) {
            return this.$api.Pt.CmsArticleComment.data({
                ...this.selectDialog.queryFormModel,
                ...pagination,
            }).then(json => {
                const res = json.data;
                this.selectDialog.tableData = res.data;
                this.selectDialog.pagination = res.pagination;
            });
        },
        onResetSelectDialog() {
            this.$refs.selectDialogQueryForm.resetFields();
        },
        onSelectCancel() {
            this.selectDialog.isVisible = false;
        },
        onDeleteComment(id) {
            this.$confirm('是否确定要删除该评论！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Pt.CmsArticleComment.delete({ ids: id }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onSelectDialogQuery(this.pagination);
                });
            });
        },
    },
    mounted() {
        setTimeout(() => {
            // TODO 级联选择器 异步数据初始化问题
            this.cascaderPicker = '';
        }, 10);
    },
};
</script>

<style lang="scss">
</style>
